import React from 'react'
import { BasicLayout } from '../layouts/basic'
import { SEOMeta } from '../components/SEOMeta'

const Contact = ({ location }) => (
  <BasicLayout>
    <SEOMeta title="Contact" path={location.pathname} />
    <div className="mx-auto p-6 pb-12 max-w-6xl text-text-primary-light text-sm leading-relaxed sm:py-48 sm:text-base">
      <h1 className="mb-6 text-2xl font-extrabold tracking-tight leading-tight sm:text-3xl">
        Contact
      </h1>
      <div className="flex flex-col sm:flex-row sm:space-x-24">
        <div className="flex-1">
          <h2 className="mb-4 text-lg font-semibold tracking-tight leading-tight">
            Want to start using Qualdesk?
          </h2>
          <p className="mb-4 max-w-prose">
            Get in touch with our team today to find out how our tools can
            benefit your organization
          </p>
          <p>
            <a
              className="hover:text-blue-600 text-lg md:text-xl"
              href="mailto:sales@qualdesk.com"
            >
              sales@qualdesk.com
            </a>
          </p>
        </div>
        <div className="flex-1 mt-12 sm:mt-0">
          <h2 className="mb-4 text-lg font-semibold tracking-tight leading-tight">
            Need help with your account?
          </h2>
          <p className="mb-4 max-w-prose">
            Contact us by email for help. We aim to respond to all of your
            questions within 24 hours, but response times may be longer over the
            weekend.
          </p>
          <p>
            <a
              className="hover:text-blue-600 text-lg md:text-xl"
              href="mailto:support@qualdesk.com"
            >
              support@qualdesk.com
            </a>
          </p>
        </div>
      </div>
    </div>
  </BasicLayout>
)

export default Contact
